import { Viewer, PanoData } from '@photo-sphere-viewer/core';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import { GalleryPlugin } from '@photo-sphere-viewer/gallery-plugin';
import { LensflarePlugin } from 'photo-sphere-viewer-lensflare-plugin';
import { GyroscopePlugin } from '@photo-sphere-viewer/gyroscope-plugin';
import { AutorotatePlugin } from '@photo-sphere-viewer/autorotate-plugin';
import { StereoPlugin } from '@photo-sphere-viewer/stereo-plugin';
import '@photo-sphere-viewer/core/index.css';
import '@photo-sphere-viewer/markers-plugin/index.css';
import '@photo-sphere-viewer/gallery-plugin/index.css';

const viewer = new Viewer({
    container: document.querySelector('#viewer') as HTMLElement,
    caption: 'Kainuun ammattiopisto - Kajaanitech NY',
    touchmoveTwoFingers: false,
    mousewheelCtrlKey: true,
    defaultYaw: '130deg',
    minFov: 50,
    maxFov: 150,
    navbar: 'autorotate zoom move gallery fullscreen',
    /* defaultZoomLvl: 45, */
    defaultZoomLvl: 1,
    fisheye: true,
    plugins: [
        MarkersPlugin,
        [GalleryPlugin, {
            thumbnailSize: { width: 100, height: 100 },
            open: true,
            opened: true
        }],
        [VirtualTourPlugin, {
            positionMode: 'manual',
            renderMode: '3d',
            preload: true,
        }],
        [GyroscopePlugin, {
            lang: {
                gyroscope: 'Giroscopio',
            }
        }],
        StereoPlugin,
        [AutorotatePlugin, {
            autorotatePitch: '5deg',
            autostartOnIdle: false
        }],
        LensflarePlugin
    ]
});

var galleryPlugin = viewer.getPlugin(GalleryPlugin) as GalleryPlugin;
galleryPlugin.show();

const virtualTour = viewer.getPlugin(VirtualTourPlugin) as VirtualTourPlugin;
const lensflare = viewer.getPlugin(LensflarePlugin) as LensflarePlugin;

virtualTour.addEventListener('node-changed', ({ node }) => {
    /* console.log(`Current node is ${node.id}`); */
    switch (node.id) {
        case 'torre_est':
/*             lensflare.setLensflares([
                {
                    id: 'sun',
                    position: { yaw: '340deg', pitch: '18deg' },
                    type: 0,
                }
            ]); */
            break;
        case 'aulajabilis':
/*             lensflare.setLensflares([
                {
                    id: 'sun1',
                    position: { yaw: '200deg', pitch: '22deg' },
                    type: 0,
                },
                {
                    id: 'light1',
                    position: { yaw: '100deg', pitch: '65deg' },
                    type: 0,
                    color: { h: 0, s: 0.4, l: 0.2 }
                },
                {
                    id: 'light2',
                    position: { yaw: '340deg', pitch: '68deg' },
                    type: 0,
                    color: { h: 0, s: 0.4, l: 0.2 }
                }
            ]); */
            break;
        case 'interno_1':
/*             lensflare.setLensflares([
                {
                    id: 'light1',
                    position: { yaw: '82deg', pitch: '55deg' },
                    type: 0,
                },
                {
                    id: 'light2',
                    position: { yaw: '130deg', pitch: '50deg' },
                    type: 0,
                }
            ]); */
            break;
        case 'interno_2':
/*             lensflare.setLensflares([
                {
                    id: 'light1',
                    position: { yaw: '235deg', pitch: '40deg' },
                    type: 0,
                },
                {
                    id: 'light2',
                    position: { yaw: '305deg', pitch: '40deg' },
                    type: 0,
                }
            ]); */
            break;
        case 'interno_3':
/*             lensflare.setLensflares([
                {
                    id: 'light1',
                    position: { yaw: '354deg', pitch: '52deg' },
                    type: 0,
                },
                {
                    id: 'light2',
                    position: { yaw: '295deg', pitch: '40deg' },
                    type: 0,
                },
                {
                    id: 'light3',
                    position: { yaw: '122deg', pitch: '56deg' },
                    type: 0,
                }
            ]); */
            break;
        default:
            lensflare.setLensflares([]);
            break;
    }
});

virtualTour.setNodes([
    {
        id: 'parkkipaikka1',
        panorama: 'images/parkkipaikka1.JPG',
        thumbnail: 'images/parkkipaikka1.jpg',
        name: 'parkkipaikka1',
        links: [],
        panoData: { poseHeading: 100 } as PanoData,
    },
    {
        id: 'parkkipaikka2',
        panorama: 'images/parkkipaikka2.JPG',
        thumbnail: 'images/parkkipaikka2.jpg',
        name: 'parkkipaikka2',
        links: [],
        panoData: { poseHeading: 100 } as PanoData,
    },

    {
        id: 'torre_est',
        panorama: 'images/torre_est.JPG',
        thumbnail: 'images/torre_est.jpg',
        name: 'Parkkipaikka',
        links: [
            /* { nodeId: 'torre_ovest', position: { textureX: 200, textureY: 5000 } }, */
        ],
        panoData: { poseHeading: 100 } as PanoData,
    },
    {
        id: 'piha1',
        panorama: 'images/piha1.JPG',
        thumbnail: 'images/piha1.jpg',
        name: 'piha1',
        links: [],
        panoData: { poseHeading: 100 } as PanoData,
    },
    {
        id: 'torre_ovest',
        panorama: 'images/torre_ovest.JPG',
        thumbnail: 'images/torre_ovest.jpg',
        name: 'Aula',
        links: [
           /*  { nodeId: 'torre_est', position: { textureX: 85000, textureY: 5000 } },
            { nodeId: 'aulajabilis', position: { textureX: 54000, textureY: 5000 } }, */
        ],
        panoData: { poseHeading: 60 } as PanoData,
    },
    {
        id: 'interno_1',
        panorama: 'images/interno_1.JPG',
        thumbnail: 'images/interno_1.jpg',
        name: 'Kakkoskerros',
        links: [
            /* { nodeId: 'interno_2', position: { textureX: 300, textureY: 5000 } }, */
        ],
        panoData: { poseHeading: -250 } as PanoData,
    },
    {
        id: 'interno_2',
        panorama: 'images/interno_2.JPG',
        thumbnail: 'images/interno_2.jpg',
        name: 'Portaisiin',
        links: [
            /* { nodeId: 'interno_1', position: { textureX: 45000, textureY: 5000 } },
            { nodeId: 'interno_3', position: { textureX: 14000, textureY: 5000 } }, */
        ],
        panoData: { poseHeading: -50 } as PanoData,
    },
    {
        id: 'interno_3',
        panorama: 'images/interno_3.JPG',
        thumbnail: 'images/interno_3.jpg',
        name: 'Hissi & WC',
        links: [
 /*            { nodeId: 'aulajabilis', position: { textureX: 16000, textureY: 5000 } },
            { nodeId: 'interno_2', position: { textureX: 1100, textureY: 5000 } } */
        ],
        panoData: { poseHeading: 40 } as PanoData,
    },
    {
        id: 'aulajabilis',
        panorama: 'images/aulajabilis.JPG',
        thumbnail: 'images/aulajabilis.jpg',
        name: 'Biljardipöytä & Aula',
        links: [
      /*       { nodeId: 'torre_ovest', position: { textureX: 225000, textureY: 5000 } }, // ala aula ovi paikka
            { nodeId: 'nuova_ala_1', position: { textureX: 700, textureY: 5000 } },
            { nodeId: 'interno_3', position: { textureX: 3000, textureY: 5000 } }, */
        ],
        panoData: { poseHeading: 200 } as PanoData,
    },
    {
        id: 'nuova_ala_1',
        panorama: 'images/nuova_ala_1.JPG',
        thumbnail: 'images/nuova_ala_1.jpg',
        name: 'Luokkatila 175',
        links: [
           /*  { nodeId: 'aulajabilis', position: { textureX: 225000, textureY: 5000 } }, */
        ],
        panoData: { poseHeading: -65 } as PanoData,
    },
    {
        id: 'paja1',
        panorama: 'images/paja1.JPG',
        thumbnail: 'images/paja1.jpg',
        name: 'Paja1',
        links: [],
        panoData: { poseHeading: 100 } as PanoData,
    },
    {
        id: 'paja2',
        panorama: 'images/paja2.JPG',
        thumbnail: 'images/paja2.jpg',
        name: 'Paja2',
        links: [],
        panoData: { poseHeading: 100 } as PanoData,
    },
    {
        id: 'paja_pelikone',
        panorama: 'images/paja_pelikone.JPG',
        thumbnail: 'images/paja_pelikone.jpg',
        name: 'Pajassa pelikone',
        links: [],
        panoData: { poseHeading: 100 } as PanoData,
    },
    {
        id: 'paja_tietokoneensisusta',
        panorama: 'images/paja_tietokoneensisusta.JPG',
        thumbnail: 'images/paja_tietokoneensisusta.jpg',
        name: 'Pajassa pelikone sisusta',
        links: [],
        panoData: { poseHeading: 100 } as PanoData,
    },
], 'aulajabilis');
